<template>
  <div class="full-card has-table">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addProjectTaskTemplate']">新增项目任务模版</a-button>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
          :scroll="{ y: 'calc(100% - 55px)'}"
      >
        <template slot="templateName" slot-scope="text, record">
          <div @click="$util.goHidden(authority.viewProjectTaskTemplateDetail, `/project/templateDetail?id=${record.id}`, text)" class="display-f align-items-c text-underline">
            <span>{{ text }}</span>
          </div>
        </template>
      </a-table>
    </a-card>
    <a-drawer
        title="新增项目任务模版"
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="600px"
        class="drawer"
        v-if="createShow"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="项目任务模版名称" prop="templateName">
              <a-input placeholder="请输入项目任务模版名称" v-model="form.templateName" />
            </a-form-model-item>
            <a-form-model-item label="复用已有模版" prop="originalTemplateId">
              <a-select v-model="form.originalTemplateId" placeholder="请选择模版">
                <a-select-option :value="item.id" v-for="(item, index) in allTemplateList" :key="item.id">{{ item.templateName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="浏览下载权限" prop="postIds">
              <a-select mode="multiple" v-model="form.postIds" placeholder="请选择浏览下载权限">
                <a-select-option :value="item.id" v-for="(item, index) in jobList" :key="item.id">{{ item.postName }}（{{ item.deptName}}）</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>

    </a-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { createTemplate, getTemplateList } from '../../api/project/template'
import { getJobList } from '../../api/humanResources/job'

export default {
  name: 'projectTaskTemplate',
  data () {
    return {
      columns: [
        {
          title: '项目任务模版名称',
          dataIndex: 'templateName',
          scopedSlots: { customRender: 'templateName' }
        },
        {
          title: '引用次数',
          dataIndex: 'refNum'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '创建人',
          dataIndex: 'creator'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      filterShow: false,
      filterForm: {
        pageNum: 1,
        pageSize: 10
      },
      form: {},
      page: 1,
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      rules: {
        templateName: [
          { required: true, message: '项目任务模版名称', trigger: 'blur' }
        ]
      },
      allTemplateList: [],
      jobList: []
    }
  },
  mounted () {
    this.getList()
    this.getJob()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getTemplateList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    getAllTemplate  () {
      getTemplateList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.allTemplateList = res.data.list
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      this.getAllTemplate()
      if (item) this.form = JSON.parse(JSON.stringify(item))
      this.createShow = true
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createTemplate(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    getJob () {
      getJobList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.jobList = res.data.list
      })
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
